import { AnnualPayload } from "types/terminal";

export async function loadAnnualizations(apiToken: string) {
  const url = `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/terminals/annualizations`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${apiToken}`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch annualizations.");
  }
  return response.json();
}

export async function loadAnnualization(annualId: number, apiToken: string) {
  const url = `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/terminals/annualizations/${annualId}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${apiToken}`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch annualizations.");
  }
  return response.json();
}

export async function loadAnnualFinancial(annualId: number, apiToken: string) {
  const url = `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/terminals/annualizations/${annualId}/financial`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${apiToken}`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch annualization financial.");
  }
  return response.json();
}

export async function addNewAnnualization(
  annualizations: AnnualPayload,
  apiToken: string
) {
  const url = `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/terminals/annualizations`;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${apiToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(annualizations),
  });

  if (!response.ok) {
    throw new Error("Failed to create annualization.");
  }
  return response.json();
}

export async function disableAnnualPlan(annualId: number, apiToken: string) {
  const url = `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/terminals/annualizations/${annualId}`;

  const response = await fetch(url, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${apiToken}`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Failed to delete annualization.");
  }
  return response.json();
}

export async function loadAnnualDownload(annualId: number, apiToken: string) {
  const url = `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/terminals/annualizations/${annualId}/download`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${apiToken}`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch annualization financial.");
  }
  return response.json();
}
