import { Divider, List, ListItem, ListItemText } from "@mui/material";
import { formatNumber } from "utils/format-number";

type AnnualizationMetaInformationProps = {
  metaInformation: {
    highWorkLoadDays: number;
    mediumWorkLoadDays: number;
    lowWorkLoadDays: number;
    peakMonthWorkload: string;
    peakMonthPowerDraw: string;
    peakMonthEnergyConsumption: string;
  };
};

const AnnualizationMetaInformation = ({
  metaInformation,
}: AnnualizationMetaInformationProps) => {
  return (
    <List dense>
      <ListItem disableGutters>
        <ListItemText
          primary="Number of high workload days (days per year)"
          secondary={formatNumber(metaInformation.highWorkLoadDays || 0)}
        />
      </ListItem>
      <Divider component="li" />
      <ListItem disableGutters>
        <ListItemText
          primary="Number of low workload days (days per year)"
          secondary={
            metaInformation.lowWorkLoadDays
              ? `${metaInformation.lowWorkLoadDays}`
              : "N/A"
          }
        />
      </ListItem>
      <Divider component="li" />
      <ListItem disableGutters>
        <ListItemText
          primary="Number of other workload days (days per year)"
          secondary={
            metaInformation.mediumWorkLoadDays
              ? `${metaInformation.mediumWorkLoadDays}`
              : "N/A"
          }
          secondaryTypographyProps={{ component: "h3" }}
        />
      </ListItem>
      <Divider component="li" />
      <ListItem disableGutters>
        <ListItemText
          primary="Peak month(s) by workload"
          secondary={metaInformation.peakMonthWorkload || "N/A"}
        />
      </ListItem>
      <Divider component="li" />
      <ListItem disableGutters>
        <ListItemText
          primary="Peak month(s) by power draw"
          secondary={metaInformation.peakMonthPowerDraw || "N/A"}
        />
      </ListItem>
      <Divider component="li" />
      <ListItem disableGutters>
        <ListItemText
          primary="Peak month(s) for energy consumption"
          secondary={metaInformation.peakMonthEnergyConsumption || "N/A"}
        />
      </ListItem>
    </List>
  );
};

export default AnnualizationMetaInformation;
