import {
  BarElement,
  BubbleDataPoint,
  CategoryScale,
  ChartData,
  ChartDataset,
  Chart as ChartJS,
  ChartOptions,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  ScatterDataPoint,
  Title,
  Tooltip,
} from "chart.js";

import { Chart } from "react-chartjs-2";
import { AnnualFinance, AnnualUtilityCost } from "types/terminal-annualization";
import Colors from "utils/colors";
import { MONTHSHORT } from "utils/month";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

type UtilitySpendChartProps = {
  financialInformation: AnnualFinance | undefined;
};

const UtilitySpendChart = ({
  financialInformation,
}: UtilitySpendChartProps) => {
  const customDatasets: ChartDataset<"bar", number[]>[] = [];

  const customLabels: string[] = [
    "Energy Charge",
    "Demand Charge",
    "Misc Charge",
  ];
  const barColors: string[] = Colors;
  const { utilityCost = [] } = (financialInformation as AnnualFinance) || {};

  customDatasets.push(
    {
      label: `${customLabels[0]}`,
      data: utilityCost.map((costs: AnnualUtilityCost) => costs.energyCost),
      backgroundColor: barColors[0],
    },
    {
      label: `${customLabels[1]}`,
      data: utilityCost.map((costs: AnnualUtilityCost) => costs.demandCost),
      backgroundColor: barColors[1],
    },
    {
      label: `${customLabels[2]}`,
      data: utilityCost.map((costs: AnnualUtilityCost) => costs.fixedMonthFees),
      backgroundColor: barColors[2],
    }
  );

  const options: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const datasetLabel = tooltipItem.dataset.label || "";
            const value = tooltipItem.raw as number;
            return `${datasetLabel}: $${value.toFixed(2)}`;
          },
        },
      },
    },
    scales: {
      x: {
        title: { text: "Months", display: true },
        grid: {
          display: false,
        },
        ticks: {
          display: true,
        },
        stacked: true,
      },
      y: {
        title: { text: "USD", display: true },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
        },
        stacked: true,
      },
    },
    maintainAspectRatio: false,
  };

  let data: ChartData<
    "bar",
    (number | ScatterDataPoint | BubbleDataPoint | null)[],
    unknown
  > = {
    datasets: [],
  };

  data = {
    labels: MONTHSHORT,
    datasets: customDatasets,
  };

  return <Chart type={"bar"} options={options} data={data} />;
};

export default UtilitySpendChart;
