import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useNewUtilityRateById } from "api/terminal/utility-rates";
import { MONTHSHORT } from "utils/month";

type UtilityRateTableProps = {
  utilityRateId: number;
};

const UtilityRateTable = ({ utilityRateId }: UtilityRateTableProps) => {
  const { utilitySeasons } = useNewUtilityRateById(utilityRateId);
  const columns = [
    {
      field: "seasonId",
      headerName: "Season",
      sortable: false,
      flex: 1,
      minWidth: 50,
    },
    {
      field: "demandOnPeak",
      headerName: "Demand Charge - On Peak ($/kW)",
      sortable: false,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "energyOnPeak",
      headerName: "Energy Charge - On Peak ($/kWh)",
      sortable: false,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "demandOffPeak",
      headerName: "Demand Charge - Off Peak ($/kW)",
      sortable: false,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "energyOffPeak",
      headerName: "Energy Charge - Off Peak ($/kWh)",
      sortable: false,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "fixedMonthlyFees",
      headerName: "Misc Charges",
      sortable: false,
      flex: 1,
      width: 100,
    },
  ];

  const seasonNames = utilitySeasons
    .map((season) => {
      return `${season.months
        .map((month) => MONTHSHORT[month - 1])
        .join(", ")} ${season.months.length > 1 ? "are" : "is"} season ${
        season.seasonId
      }`;
    })
    .join(", ");

  return (
    <Box height={328} marginTop={2}>
      <DataGrid
        columns={columns}
        rows={utilitySeasons}
        hideFooterSelectedRowCount
        rowsPerPageOptions={[5, 10, 15, 20]}
        pageSize={5}
        headerHeight={60}
        selectionModel={[]}
        disableColumnMenu
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            wordWrap: "break-word",
            textAlign: "left",
            lineHeight: "1.2",
            fontSize: "12px",
          },
        }}
      />
      <Typography
        variant="body2"
        sx={{
          fontSize: 12,
          marginTop: 1,
        }}
      >
        {seasonNames}
      </Typography>
    </Box>
  );
};

export default UtilityRateTable;
