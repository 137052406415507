import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Box,
  Button,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { DownloadButton } from "dashboard/controls/DownloadButton";
import StickyBottomBox from "dashboard/controls/StickyBottomBox";
import { Annualization } from "types/terminal";
import { WorkloadConfig } from "types/terminal-annualization";
import AnnualChargerTable from "./AnnualChargerTable";
import AnnualVehicleTable from "./AnnualVehicleTable";

type AnnualizationSideBarProps = {
  annualData: Annualization | undefined;
  selectedAnnualization: Annualization;
  workload: WorkloadConfig | undefined;
  downloadHandler: () => void;
  setSelectedAnnualization: (annualId: Annualization | undefined) => void;
  isDownloading: boolean;
};

const AnnualizationSideBar = ({
  annualData,
  selectedAnnualization,
  workload,
  downloadHandler,
  setSelectedAnnualization,
  isDownloading,
}: AnnualizationSideBarProps) => {
  return (
    <Stack
      sx={{
        width: "462px",
        height: "100%",
        borderRight: "0.5px solid silver",
      }}
      divider={
        <Divider
          orientation="horizontal"
          flexItem
          sx={{ marginRight: "30px", marginLeft: "30px" }}
        />
      }
    >
      <Box
        sx={{
          overflowY: "scroll",
        }}
      >
        <Box sx={{ margin: "20px", marginBottom: 2 }}>
          <Button
            variant="outlined"
            onClick={() => setSelectedAnnualization(undefined)}
          >
            Back
          </Button>
        </Box>
        <Box margin={2}>
          <Stack sx={{ height: "100%" }} alignItems={"start"}>
            <Box>
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <Typography variant="controlTitle">
                  Configurations Included
                </Typography>
                <Button
                  onClick={() => {}}
                  color="info"
                  startIcon={<HelpOutlineIcon />}
                  variant={"outlined"}
                  size={"small"}
                >
                  Tutorial
                </Button>
              </Stack>
              <Box marginTop={2}>
                <TableContainer
                  component={Paper}
                  sx={{ boxShadow: "none", marginTop: 2 }}
                >
                  <Table
                    sx={{
                      border: "1px solid #e0e0e0",
                      tableLayout: "fixed",
                      width: "100%",
                    }}
                  >
                    <TableBody>
                      {annualData?.configs?.map((config, index) => (
                        <TableRow key={index}>
                          <TableCell
                            sx={{
                              border: "1px solid #e0e0e0",
                              width: "100%",
                            }}
                          >
                            <Typography variant="h4">
                              {config.scenarioName}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
            <Divider orientation="horizontal" />
            <Box marginTop={2}>
              <Typography variant="controlTitle">
                Infrastructure Information
              </Typography>
              <Table
                sx={{
                  border: "1px solid #e0e0e0",
                  tableLayout: "fixed",
                  width: "100%",
                  marginTop: 1,
                }}
              >
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{
                        border: "1px solid #e0e0e0",
                        width: "50%",
                      }}
                    >
                      Planning Horizon (Years)
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "1px solid #e0e0e0",
                        width: "50%",
                      }}
                    >
                      {workload?.planningHorizonYears || 0}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <AnnualVehicleTable
                vehicleInfo={{
                  ...selectedAnnualization.evVehicle,
                  fleetSize: selectedAnnualization.fleetSize,
                  vehicleReplacementLifecycleYears:
                    workload?.vehicleReplacementLifecycleYears,
                  priceUsd:
                    workload?.vehicleCost ??
                    selectedAnnualization.evVehicle.priceUsd,
                }}
              />
              <AnnualChargerTable
                chargerInfo={{
                  ...selectedAnnualization.charger,
                  optNumChargers: selectedAnnualization.optNumChargers,
                  batteryReplacementLifecycleYears:
                    workload?.batteryReplacementLifecycleYears,
                  chargerReplacementLifecycleYears:
                    workload?.chargerReplacementLifecycleYears,
                  priceUsd:
                    workload?.chargerCost ??
                    selectedAnnualization.charger.priceUsd,
                }}
              />
            </Box>
          </Stack>
        </Box>
      </Box>
      <StickyBottomBox>
        <DownloadButton
          onClick={downloadHandler}
          // error={errorDownloading}
          loading={isDownloading}
          innerText={"Download Annual Data"}
        />
      </StickyBottomBox>
    </Stack>
  );
};

export default AnnualizationSideBar;
