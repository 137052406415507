import * as ExcelJs from "exceljs";
import { saveAs } from "file-saver";
import { AnnualSeason } from "types/terminal";
import { UtilityRateStructure } from "types/terminal-scenario";
import { MONTHSHORT } from "utils/month";
import { generate24HourLabels } from "utils/time";
import { createOuterBorder } from "./annualizationDownload";
import { loadUtilityDownload } from "api/terminal/utility-rates";

const convert48To24HourRates = (profile: number[]): number[] => {
  return Array.from({ length: 24 }, (_, i) =>
    Math.max(Number(profile[i * 2]), Number(profile[i * 2 + 1]))
  );
};

export const utilityRateDownload = async (
  utilityRateOfMonth: UtilityRateStructure,
  selectedMonth: string,
  apiToken: string
) => {
  let downloadSuccess = true;
  const utilitySeason: AnnualSeason[] = await loadUtilityDownload(
    utilityRateOfMonth.id,
    apiToken
  );
  try {
    const wb = new ExcelJs.Workbook();
    const sheet1 = wb.addWorksheet("Utility Rate");

    sheet1.addRow(["Utility Rate Structure"]);
    sheet1.getCell("A1").font = { bold: true };
    sheet1.addRow([]);
    sheet1.addRow(["Name", utilityRateOfMonth.name]);
    sheet1.addRow([]);
    sheet1.addRow([`Utility Rate Components of ${selectedMonth}`]);
    sheet1.getCell("A5").font = { bold: true };

    sheet1.addRow([]);
    sheet1.addRow([
      "Fixed Monthly Fees ($)",
      utilityRateOfMonth.fixedMonthlyFees,
    ]);
    sheet1.addRow([]);
    sheet1.addRow(["", ...generate24HourLabels()]);
    sheet1.addRow([
      "Energy Charge ($/kWh)",
      ...convert48To24HourRates(utilityRateOfMonth.energyUsageCharge ?? []),
    ]);
    sheet1.addRow([
      "Demand Charge ($/kW)",
      ...convert48To24HourRates(utilityRateOfMonth.demandUsageCharge ?? []),
    ]);
    createOuterBorder(sheet1, { row: 9, col: 1 }, { row: 11, col: 25 });

    sheet1.addRow([]);
    sheet1.addRow([`Complete 12 months Rate`]);
    sheet1.getCell("A13").font = { bold: true };

    sheet1.addRow([]);
    sheet1.addRow([
      "Fixed Monthly Fees ($)",
      utilityRateOfMonth.fixedMonthlyFees,
    ]);
    sheet1.addRow([]);
    sheet1.addRow(["Energy Charge ($/kWh)"]);
    sheet1.getCell("A17").font = { bold: true };

    sheet1.addRow(["", ...generate24HourLabels()]);
    MONTHSHORT.forEach((month, index) => {
      const season = utilitySeason.find((season) =>
        season.months.includes(index + 1)
      );
      sheet1.addRow([month, ...(season?.energyUsageCharge ?? [])]);
    });
    createOuterBorder(sheet1, { row: 18, col: 1 }, { row: 30, col: 25 });
    sheet1.addRow([]);

    sheet1.addRow(["Demand Charge $/kW)"]);
    sheet1.getCell("A32").font = { bold: true };

    sheet1.addRow(["", ...generate24HourLabels()]);
    MONTHSHORT.forEach((month, index) => {
      const season = utilitySeason.find((season) =>
        season.months.includes(index + 1)
      );
      sheet1.addRow([month, ...(season?.demandUsageCharge ?? [])]);
    });
    createOuterBorder(sheet1, { row: 33, col: 1 }, { row: 45, col: 25 });

    const filename = `utility_rate_download-${utilityRateOfMonth?.name}.xlsx`;
    await wb.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, filename);
    });
  } catch (error) {
    console.log(error);
    downloadSuccess = false;
  }
  return downloadSuccess;
};
