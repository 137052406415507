import {
  Backdrop,
  Box,
  CircularProgress,
  Collapse,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import {
  loadAnnualFinancial,
  loadAnnualization,
} from "api/terminal/terminals-annualization";
import { Section } from "components/Section";
import { annualizationDownload } from "dashboard/terminal/annualizationDownload";
import { useEffect, useState } from "react";
import { Annualization } from "types/terminal";
import { AnnualFinance } from "types/terminal-annualization";
import { useAccessToken } from "utils/get-access-token";
import {
  calculateAnnualMoneySpendInformation,
  calculateMetaInformation,
} from "utils/terminal-annual-calculation";
import UtilitySpendChart from "./chart/UtilitySpendChart";
import AnnualizationMetaInformation from "./components/AnnualMetaInformation";
import AnnualMoneySpendInformation from "./components/AnnualMoneySpendInformation";
import AnnualizationSideBar from "./components/AnnualSideBar";
import UtilityRateTable from "./components/UtilityRateStructureTable";

type AnnualizationPageProps = {
  selectedAnnualization: Annualization;
  setSelectedAnnualization: (annualId: Annualization | undefined) => void;
};

const AnnualizationPage = ({
  selectedAnnualization,
  setSelectedAnnualization,
}: AnnualizationPageProps) => {
  const { getToken } = useAccessToken();
  const [annualData, setAnnualData] = useState<Annualization | undefined>(
    undefined
  );
  const [financialInformation, setFinancialInformation] = useState<
    AnnualFinance | undefined
  >(undefined);
  const [annualMoneySpendInformation, setAnnuaMoneySpendInformation] = useState(
    {
      totalElectricityCost: 0,
      maximumElectricityCost: 0,
      totalCapEx: 0,
      totalOpEx: 0,
    }
  );
  const [metaInformation, setMetaInformation] = useState({
    highWorkLoadDays: 0,
    mediumWorkLoadDays: 0,
    lowWorkLoadDays: 0,
    peakMonthWorkload: "",
    peakMonthPowerDraw: "",
    peakMonthEnergyConsumption: "",
  });
  const [loadingAnnualization, setLoadingAnnualization] =
    useState<boolean>(true);
  const [loadingAnnualFinance, setLoadingAnnualFinance] =
    useState<boolean>(true);
  const [downloadingAnnualization, setDownloadingAnnualization] =
    useState<boolean>(false);

  useEffect(() => {
    async function loadAnnualData() {
      setLoadingAnnualization(true);
      const apiToken = await getToken();
      const annualizations = await loadAnnualization(
        selectedAnnualization.id,
        apiToken
      );
      setAnnualData(annualizations);
      setLoadingAnnualization(false);
    }
    async function loadAnnualFinance() {
      setLoadingAnnualFinance(true);
      const apiToken = await getToken();
      const annualizationFinance = await loadAnnualFinancial(
        selectedAnnualization.id,
        apiToken
      );
      setFinancialInformation(annualizationFinance);
      setLoadingAnnualFinance(false);
    }
    loadAnnualData();
    loadAnnualFinance();
  }, []);

  useEffect(() => {
    if (financialInformation?.utilityCost) {
      setAnnuaMoneySpendInformation(
        calculateAnnualMoneySpendInformation(financialInformation)
      );
      setMetaInformation(calculateMetaInformation(financialInformation));
    }
  }, [financialInformation]);

  const downloadAnnualizationHandler = async () => {
    setDownloadingAnnualization(true);
    const apiToken = await getToken();
    await annualizationDownload([selectedAnnualization.id], apiToken);
    setDownloadingAnnualization(false);
  };

  return (
    <Stack direction={"row"} sx={{ height: "100%" }}>
      <Backdrop
        sx={{
          color: "#FFFFFF",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
        }}
        open={loadingAnnualFinance || loadingAnnualization}
      >
        <Stack alignItems={"center"} spacing={2}>
          <CircularProgress color="inherit" />
          <Collapse in={loadingAnnualFinance}>
            <Typography variant="h3">Loading Annual Finance...</Typography>
          </Collapse>
          <Collapse in={loadingAnnualization}>
            <Typography variant="h3">Loading Annualizations...</Typography>
          </Collapse>
        </Stack>
      </Backdrop>
      <AnnualizationSideBar
        annualData={annualData}
        workload={financialInformation?.workLoads.configs?.[0]}
        selectedAnnualization={selectedAnnualization}
        downloadHandler={downloadAnnualizationHandler}
        setSelectedAnnualization={setSelectedAnnualization}
        isDownloading={downloadingAnnualization}
      />
      <Box
        sx={{
          height: "100%",
          flex: 1,
          position: "relative",
          paddingLeft: 2,
          paddingRight: 2,
          overflowY: "scroll",
        }}
      >
        <Section>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            marginBottom={3}
            marginTop={2}
          >
            <Typography variant="controlTitle" sx={{ fontWeight: 600 }}>
              Annual Plan Summary - {selectedAnnualization.name}
            </Typography>
          </Grid>
          <Grid container rowSpacing={2} columnSpacing={5} marginTop={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
              <Box>
                <Typography
                  variant="controlTitle"
                  sx={{ fontWeight: 400, textDecoration: "underline" }}
                >
                  Total Cost of Ownership
                </Typography>
              </Box>
              <AnnualMoneySpendInformation
                annualMoneySpendInformation={annualMoneySpendInformation}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
              <Box>
                <Typography
                  variant="controlTitle"
                  sx={{ fontWeight: 400, textDecoration: "underline" }}
                >
                  Plan Metadata
                </Typography>
              </Box>
              <AnnualizationMetaInformation metaInformation={metaInformation} />
            </Grid>
          </Grid>
        </Section>
        <Grid container rowSpacing={2} columnSpacing={2} marginBottom={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
            <Section>
              <Box sx={{ marginBottom: 3 }}>
                <Typography variant="controlTitle" sx={{ fontWeight: 600 }}>
                  Utility Expenses (Monthly)
                </Typography>
              </Box>
              <Box height={345}>
                <UtilitySpendChart
                  financialInformation={financialInformation}
                />
              </Box>
            </Section>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
            <Section>
              <Box sx={{ marginBottom: 3 }}>
                <Typography variant="controlTitle" sx={{ fontWeight: 600 }}>
                  Utility Rate Structure
                </Typography>
                <UtilityRateTable
                  utilityRateId={selectedAnnualization.utilityRateId}
                />
              </Box>
            </Section>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default AnnualizationPage;
