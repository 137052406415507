import { Divider, List, ListItem, ListItemText } from "@mui/material";
import { formatCurrency } from "utils/format-number";

type AnnualMoneySpendInformationProps = {
  annualMoneySpendInformation: {
    totalElectricityCost: number;
    maximumElectricityCost: number;
    totalCapEx: number;
    totalOpEx: number;
  };
};

const AnnualMoneySpendInformation = ({
  annualMoneySpendInformation,
}: AnnualMoneySpendInformationProps) => {
  return (
    <List dense>
      <ListItem disableGutters>
        <ListItemText
          primary="Annual electricity cost (USD)"
          secondary={formatCurrency(
            annualMoneySpendInformation.totalElectricityCost
          )}
        />
      </ListItem>
      <Divider component="li" />
      <ListItem disableGutters>
        <ListItemText
          primary="Maximum monthly electricity cost (USD)"
          secondary={formatCurrency(
            annualMoneySpendInformation.maximumElectricityCost
          )}
          secondaryTypographyProps={{ component: "h3" }}
        />
      </ListItem>
      <Divider component="li" />
      <ListItem disableGutters>
        <ListItemText
          primary="Total CapEx over planning horizon (USD)"
          secondary={formatCurrency(annualMoneySpendInformation.totalCapEx)}
        />
      </ListItem>
      <Divider component="li" />
      <ListItem disableGutters>
        <ListItemText
          primary="Total OpEx over planning horizon (USD)"
          secondary={formatCurrency(annualMoneySpendInformation.totalOpEx)}
        />
      </ListItem>
    </List>
  );
};

export default AnnualMoneySpendInformation;
