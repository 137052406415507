import { AnnualFinance } from "types/terminal-annualization";
import { MONTH } from "./month";

// Helper function to calculate annual money spend information
export const calculateAnnualMoneySpendInformation = (
  financialInformation: AnnualFinance
) => ({
  totalElectricityCost: financialInformation.utilityCost.reduce(
    (total, utility) => total + (utility.monthlyCost || 0),
    0
  ),
  maximumElectricityCost: financialInformation.utilityCost.length
    ? Math.max(
        ...financialInformation.utilityCost.map(
          (utility) => utility.monthlyCost || 0
        )
      )
    : 0,
  totalCapEx:
    financialInformation.financialInformation.totalCapitalExpenses
      .totalCapitalExpenses || 0,
  totalOpEx:
    financialInformation.financialInformation.totalOperationalExpenses
      .totalOperationalExpenses || 0,
});

// Helper function to calculate meta information
export const calculateMetaInformation = (
  financialInformation: AnnualFinance
) => ({
  highWorkLoadDays:
    financialInformation.workLoads?.configs?.[0]?.dayApplied?.reduce(
      (sum, days) => sum + days,
      0
    ) || 0,
  mediumWorkLoadDays:
    financialInformation.workLoads?.configs?.length > 2
      ? financialInformation.workLoads.configs
          .slice(1, -1)
          .reduce(
            (sum, config) =>
              sum +
              (config.dayApplied?.reduce((subSum, days) => subSum + days, 0) ||
                0),
            0
          )
      : 0,
  lowWorkLoadDays:
    financialInformation.workLoads?.configs?.length === 1
      ? 0
      : financialInformation.workLoads?.configs?.length > 0
      ? financialInformation.workLoads.configs[
          financialInformation.workLoads.configs.length - 1
        ]?.dayApplied?.reduce((sum, days) => sum + days, 0) || 0
      : 0,
  peakMonthWorkload:
    financialInformation.workLoads?.peakMonthWorkLoad.length > 0
      ? financialInformation.workLoads?.peakMonthWorkLoad
          .map((workload) => MONTH[workload.month - 1])
          .join(", ")
      : "N/A",
  peakMonthPowerDraw:
    financialInformation.utilityCost?.length > 0
      ? financialInformation.utilityCost
          .map((utility) => utility.demandUsage)
          .reduce<string[]>((acc, usage, idx, arr) => {
            const max = Math.max(...arr);
            if (usage === max) acc.push(MONTH[idx]);
            return acc;
          }, [])
          .join(", ")
      : "",
  peakMonthEnergyConsumption:
    financialInformation.utilityCost?.length > 0
      ? financialInformation.utilityCost
          .map((utility) => utility.energyUsage)
          .reduce<string[]>((acc, usage, idx, arr) => {
            const max = Math.max(...arr);
            if (usage === max) acc.push(MONTH[idx]);
            return acc;
          }, [])
          .join(", ")
      : "",
});
