import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { AnnualVehicle } from "types/terminal";

type AnnualVehicleTableProps = {
  vehicleInfo: AnnualVehicle;
};

const AnnualVehicleTable = ({ vehicleInfo }: AnnualVehicleTableProps) => {
  return (
    <Box marginTop={2}>
      <Box marginTop={1}>
        <Typography
          variant="h3"
          sx={{ fontWeight: 350, textDecoration: "underline" }}
        >
          Vehicles
        </Typography>
      </Box>
      <Box>
        <TableContainer
          component={Paper}
          sx={{ boxShadow: "none", marginTop: 2 }}
        >
          <Table
            sx={{
              border: "1px solid #e0e0e0",
              tableLayout: "fixed",
              width: "100%",
            }}
          >
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    border: "1px solid #e0e0e0",
                    width: "50%",
                  }}
                >
                  Make
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #e0e0e0",
                    width: "50%",
                  }}
                >
                  {vehicleInfo.make || "N/A"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    border: "1px solid #e0e0e0",
                    width: "50%",
                  }}
                >
                  Model
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #e0e0e0",
                    width: "50%",
                  }}
                >
                  {vehicleInfo.model || "N/A"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    border: "1px solid #e0e0e0",
                    width: "50%",
                  }}
                >
                  Fleet Size
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #e0e0e0",
                    width: "50%",
                  }}
                >
                  {vehicleInfo.fleetSize || 0}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    border: "1px solid #e0e0e0",
                    width: "50%",
                  }}
                >
                  Unit MSRP
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #e0e0e0",
                    width: "50%",
                  }}
                >
                  ${vehicleInfo.priceUsd || 0}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    border: "1px solid #e0e0e0",
                    width: "50%",
                  }}
                >
                  Battery Capacity
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #e0e0e0",
                    width: "50%",
                  }}
                >
                  {vehicleInfo.batteryCapacityKwh || 0} KWH
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    border: "1px solid #e0e0e0",
                    width: "50%",
                  }}
                >
                  Buy America Compliant
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #e0e0e0",
                    width: "50%",
                  }}
                >
                  {vehicleInfo.buyAmericaCompliance ? "Yes" : "No"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    border: "1px solid #e0e0e0",
                    width: "50%",
                  }}
                >
                  Vehicle Replacement Life Cycle Years
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #e0e0e0",
                    width: "50%",
                  }}
                >
                  {vehicleInfo.vehicleReplacementLifecycleYears || 0}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default AnnualVehicleTable;
