import {
  Button,
  List,
  ListItem,
  ListItemText,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { utilityRateDownload } from "dashboard/terminal/utilityRateDownload";
import { UtilityRateStructure } from "types/terminal-scenario";
import { TerminalScenarioVehicle } from "types/terminal-scenario-vehicle";
import { useAccessToken } from "utils/get-access-token";
import { MONTH } from "utils/month";
import { generate24HourLabels } from "utils/time";

export type UtilityInformationProp = {
  utilityInfo: UtilityRateStructure | undefined;
  terminalScenarioVehicle: TerminalScenarioVehicle | null;
};
const UtilityInformation = ({
  utilityInfo,
  terminalScenarioVehicle,
}: UtilityInformationProp) => {
  const { getToken } = useAccessToken();
  const convert48To24HourRates = (originalArray: number[]) => {
    const newArray = [];
    for (let i = 0; i < originalArray.length; i += 2) {
      const maxVal = Math.max(originalArray[i], originalArray[i + 1] || 0);
      newArray.push(Number(maxVal.toFixed(2)));
    }
    return newArray;
  };

  const powerUsage = convert48To24HourRates(
    utilityInfo?.demandUsageCharge || []
  );
  const energyUsage = convert48To24HourRates(
    utilityInfo?.energyUsageCharge || []
  );

  const downloadUtility = async () => {
    if (utilityInfo) {
      const apiToken = await getToken();
      utilityRateDownload(
        utilityInfo,
        MONTH[(terminalScenarioVehicle?.scenario.rateAppliedMonth || 1) - 1],
        apiToken
      );
    }
  };

  return (
    <Box
      sx={{
        border: 0.5,
        borderColor: "silver",
        padding: "16px",
        borderRadius: 5,
        marginBottom: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Typography variant="controlTitle" sx={{ fontWeight: 600 }}>
          Utility Rate Components of&nbsp;
          {MONTH[(terminalScenarioVehicle?.scenario.rateAppliedMonth || 1) - 1]}
        </Typography>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {generate24HourLabels().map((column, index) => (
                <TableCell
                  key={index}
                  sx={{
                    paddingX: "2px",
                    fontSize: "10px",
                    minWidth: "40px",
                    textAlign: "center",
                  }}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Energy ($/kWh)</TableCell>
              {energyUsage.map((value, index) => (
                <TableCell
                  key={index}
                  sx={{
                    paddingX: "2px",
                    fontSize: "10px",
                    minWidth: "40px",
                    textAlign: "center",
                  }}
                >
                  {value}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell>Demand ($/kW)</TableCell>
              {powerUsage.map((value, index) => (
                <TableCell
                  key={index}
                  sx={{
                    paddingX: "2px",
                    fontSize: "10px",
                    minWidth: "40px",
                    textAlign: "center",
                  }}
                >
                  {value}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Stack width={"25%"}>
        <List dense>
          <ListItem disableGutters>
            <ListItemText
              primary="Fixed Monthly Fees"
              secondary={`$${utilityInfo?.fixedMonthlyFees ?? 0}`}
            />
          </ListItem>
        </List>
      </Stack>

      <Button
        sx={{ marginTop: 2, width: "25%" }}
        color="info"
        variant={"contained"}
        size={"medium"}
        onClick={downloadUtility}
      >
        Download
      </Button>
    </Box>
  );
};

export default UtilityInformation;
