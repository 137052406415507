import { Visibility } from "@mui/icons-material";
import { Checkbox, Tooltip } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useState } from "react";
import { Annualization } from "types/terminal";

type AnnualizationListTableProps = {
  annualizations: Annualization[];
  setSelectedAnnualization: (annualization: Annualization) => void;
  selectedAnnualIds: number[];
  setSelectedAnnualIds: (annualId: number) => void;
};

const AnnualizationListTable = ({
  annualizations,
  setSelectedAnnualization,
  selectedAnnualIds,
  setSelectedAnnualIds,
}: AnnualizationListTableProps) => {
  const [pageSize, setPageSize] = useState<number>(10);
  const navigateToAnnualization = (annualizationId: number) => {
    setSelectedAnnualization(
      annualizations.filter((annual) => annual.id === annualizationId)[0]
    );
  };

  const annualizationColumns = [
    {
      field: " ",
      header: " ",
      width: 40,
      sortable: false,
      renderCell: (params: any) => (
        <Checkbox
          color="default"
          sx={{ minWidth: "30px" }}
          checked={selectedAnnualIds.includes(params.row.id)}
          onClick={() => setSelectedAnnualIds(params.row.id)}
        />
      ),
    },
    {
      field: "name",
      headerName: "Annualization Name",
      flex: 1,
      sortable: false,
    },
    {
      field: "property.name",
      headerName: "Terminal",
      flex: 1,
      valueGetter: (params: any) => params.row.property.name,
      sortable: false,
    },
    {
      field: "terminalFacility.name",
      headerName: "Cost Center",
      flex: 1,
      valueGetter: (params: any) => params.row.terminalFacility.name,
      sortable: false,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      cellClassName: "actions",
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={
            <Tooltip title="View Annualization">
              <Visibility />
            </Tooltip>
          }
          label="View"
          className="view-action"
          onClick={() => navigateToAnnualization(params.row.id)}
        />,
      ],
    },
  ];

  return (
    <DataGrid
      columns={annualizationColumns}
      rows={annualizations}
      hideFooterSelectedRowCount
      rowsPerPageOptions={[5, 10, 15, 20]}
      pageSize={pageSize}
      onPageSizeChange={(pageSize) => setPageSize(pageSize)}
      selectionModel={[]}
      disableColumnMenu
    />
  );
};

export default AnnualizationListTable;
