import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { ChartData, ChartDataset, ChartOptions, Tick } from "chart.js";

import HelpTooltip from "components/HelpTooltip";
import InputSlider from "components/InputSlider";
import { levenbergMarquardt } from "ml-levenberg-marquardt";
import { useEffect, useState } from "react";
import { Chart } from "react-chartjs-2";
import { VehicleClass, VehicleClassConfiguration } from "types/vehicle-class";

function logisticCurve(x: number, L: number, x0: number, k: number) {
  return L / (1 + Math.E ** (-k * (x - x0)));
}

function logisticCurveFixedMaxGenerator(maxValue: number) {
  return ([midPoint, steepness]: number[]) => {
    return (x: number) => logisticCurve(x, maxValue, midPoint, steepness);
  };
}

const customSalesCurveOptions: ChartOptions = {
  plugins: {
    legend: {
      position: "top",
    },
    tooltip: {
      callbacks: {
        title: function (tooltipItem) {
          // return x value of datapoint as title
          return typeof tooltipItem[0].dataset.data[
            tooltipItem[0].dataIndex
          ] === "object"
            ? Object(tooltipItem[0].dataset.data[tooltipItem[0].dataIndex])
                .label
            : tooltipItem[0].label;
        },
      },
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        callback: function (
          value: string | number,
          index: number,
          ticks: Tick[]
        ) {
          return value + "%";
        },
      },
      max: 100,
    },
    x: {
      ticks: {
        maxRotation: 90,
        minRotation: 90,
        labelOffset: -6,
      },
      min: 2020,
    },
  },
  maintainAspectRatio: false,
  responsive: true,
};

const customSalesCurveYears = Array.from(new Array(31), (x, i) => i + 2020);

export function getLogisticCurveParametersFromVehicleClass(
  vehicleClass: VehicleClass
) {
  const { config } = vehicleClass;

  const dataToFit = {
    x: [
      config.startYear,
      config.currentYear,
      config.targetYear,
      config.finalYear,
    ],
    y: [
      config.startMarketshare,
      config.currentMarketshare,
      config.targetMarketshare,
      config.finalMarketshare,
    ],
  };

  const fittingOptions = {
    damping: 1.5,
    initialValues: [2035, 0.17],
    gradientDifference: 10e-2,
    maxIterations: 100,
    errorTolerance: 10e-5,
  };
  const fittedParams = levenbergMarquardt(
    dataToFit,
    logisticCurveFixedMaxGenerator(config.finalMarketshare),
    fittingOptions
  );

  const [x0, k] = fittedParams.parameterValues;
  return {
    L: config.finalMarketshare,
    x0,
    k,
  };
}

type SalesCurvePageProps = {
  onClose: () => void;
  onNextButtonClick: () => void;
  onBackButtonClick: () => void;
  vehicleClass: VehicleClass;
  onVehicleClassChange: (vehicleClass: VehicleClass) => void;
  lastPage: boolean;
  model: number | undefined;
};
export default function SalesCurvePage(props: SalesCurvePageProps) {
  const { vehicleClass, model } = props;
  const { config } = vehicleClass;
  const [error, setError] = useState({
    startingPopulation: "",
    referenceYear: "",
    evPopulation: "",
  });
  const preferredModelYear = model === 1 ? 2010 : 2020;

  const customSalesCurveData: ChartData = {
    labels: customSalesCurveYears,
    datasets: [
      {
        type: "line",
        label: "Percent of Sales",
        order: 1,
        data: [],
        borderColor: "rgb(5, 194, 204)",
        backgroundColor: "rgb(5, 194, 204, 0.5)",
      },
    ],
  };

  const pointsToDisplay = {
    x:
      config.finalYear > customSalesCurveYears[customSalesCurveYears.length - 1]
        ? [config.currentYear, config.targetYear]
        : [config.currentYear, config.targetYear, config.finalYear],
    y: [
      config.currentMarketshare,
      config.targetMarketshare,
      config.finalMarketshare,
    ],
  };

  const { x0, k, L } = getLogisticCurveParametersFromVehicleClass(vehicleClass);

  customSalesCurveData.datasets[0].data = customSalesCurveYears.map((year) =>
    logisticCurve(year, L * 100, x0, k)
  );
  const fittedDataPointsDataset: ChartDataset = {
    type: "scatter",
    label: "Input Values",
    order: 0,
    data: pointsToDisplay.x.map((x, index) => {
      const year = x.toString();
      const label = customSalesCurveYears.includes(x) ? year : "";
      return {
        x: x,
        y: pointsToDisplay.y[index] * 100,
        label: label, //TODO: make this label the tooltip title for fitted points
      };
    }),
    borderColor: "rgb(255, 60, 60)",
    backgroundColor: "rgb(255, 120, 120, 0.5)",
  };
  customSalesCurveData.datasets.push(fittedDataPointsDataset);

  const nextDisabled =
    config.startingPopulation <= 0 ||
    config.startYear < preferredModelYear ||
    Object.values(error).some((err) => err !== "");

  const buttonText = props.lastPage ? "Submit" : "Next";

  const updateGrowthScenarioRelativeFields = (
    value: number,
    fieldName: keyof VehicleClassConfiguration
  ) => {
    const newVehicleClass = structuredClone(vehicleClass);
    const { config } = newVehicleClass;

    if (!value) {
      value = 0;
    }

    switch (fieldName) {
      case "evPenetration":
        config.evPenetration = Math.min(Number(value.toFixed(2)) / 100, 1);
        config.evPopulation =
          Math.min(
            Math.floor(
              Number(((value / 100) * config.startingPopulation).toFixed(2))
            ),
            Math.floor(1 * config.startingPopulation)
          ) || 0;
        break;

      case "evPopulation":
        config.evPopulation = Math.min(
          Number(value.toFixed(2)),
          1 * config.startingPopulation
        );
        config.evPenetration =
          Number(
            (config.evPopulation / config.startingPopulation).toFixed(2)
          ) || 0;
        break;

      case "startingPopulation":
        config.startingPopulation = Number(value.toFixed(2));
        config.evPopulation = Math.min(
          Math.floor(
            Number(
              (config.evPenetration * config.startingPopulation).toFixed(2)
            )
          ),
          Math.floor(1 * config.startingPopulation)
        );
        break;

      default:
        break;
    }
    props.onVehicleClassChange(newVehicleClass);
  };

  const validateReferenceYear = (year: number) => {
    if (
      year < preferredModelYear ||
      year > config.currentYear - 1 ||
      year.toString().length !== 4
    ) {
      return `Year must be between ${preferredModelYear} and ${
        config.currentYear - 1
      }`;
    }
    return "";
  };

  const validateEvPopulation = (value: number) => {
    const maxEvPopulation = config.startingPopulation;
    return value > maxEvPopulation
      ? `Value cannot exceed vehicle class population`
      : "";
  };

  const validateStartingPopulation = (value: number) => {
    return value > 3000000000 ? `Value cannot exceed 300M.` : "";
  };

  useEffect(() => {
    const response = validateReferenceYear(config.startYear);
    setError((prev) => ({
      ...prev,
      referenceYear: response,
    }));
  }, []);

  return (
    <>
      <DialogContent>
        <Grid container direction={"column"} rowGap={2} marginBottom={1}>
          <Grid item>
            <Typography variant="h2">
              Settings for {vehicleClass.name}
            </Typography>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <Typography variant="h3" marginBottom={3}>
              Population
            </Typography>
            <Grid container direction={"row"} spacing={2}>
              <Grid item md={3} xs={12}>
                <TextField
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    min: `"${model === 1 ? 2010 : 2020}"`,
                    max: config.currentYear - 1,
                    maxLength: 4,
                  }}
                  fullWidth
                  type={"number"}
                  label={"Reference Year"}
                  value={config.startYear}
                  onChange={(event) => {
                    const year = Number(event.target.value);
                    const newVehicleClass = structuredClone(vehicleClass);
                    newVehicleClass.config.startYear = year;
                    props.onVehicleClassChange(newVehicleClass);
                  }}
                  onBlur={(event) => {
                    const year = Number(event.target.value);
                    const errorMessage = validateReferenceYear(year);
                    setError((prev) => ({
                      ...prev,
                      referenceYear: errorMessage,
                    }));

                    if (!errorMessage) {
                      const newVehicleClass = structuredClone(vehicleClass);
                      newVehicleClass.config.startYear = year;
                      props.onVehicleClassChange(newVehicleClass);
                    }
                  }}
                  error={error.referenceYear.length > 0}
                  helperText={error.referenceYear}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    min: "0",
                  }}
                  fullWidth
                  type={"number"}
                  label={"Vehicle Class Population"}
                  value={config.startingPopulation || 0}
                  onKeyDown={(event) => {
                    if (
                      event.key === "-" ||
                      event.key === "e" ||
                      event.key === "."
                    ) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(event) => {
                    const value = Number(event.target.value);
                    const errorMessage = validateStartingPopulation(value);
                    setError((prev) => ({
                      ...prev,
                      startingPopulation: errorMessage,
                    }));

                    if (!errorMessage) {
                      updateGrowthScenarioRelativeFields(
                        value,
                        "startingPopulation"
                      );
                    }
                  }}
                  error={error.startingPopulation.length > 0}
                  helperText={error.startingPopulation}
                />
              </Grid>
              <Grid item md={5} xs={12}>
                <Box>
                  <HelpTooltip title="The average annual growth rate of vehicles-in-operation. This cannot exceed 7%.">
                    <Typography>Vehicle Population Growth Rate</Typography>
                  </HelpTooltip>
                  <InputSlider
                    min={0}
                    max={7}
                    step={1}
                    value={Number((config.growthRate * 100).toFixed(2))}
                    onChange={(newGrowthRate) => {
                      if (newGrowthRate <= 7) {
                        const newVehicleClass = structuredClone(vehicleClass);
                        newVehicleClass.config.growthRate =
                          Number(newGrowthRate) / 100;
                        props.onVehicleClassChange(newVehicleClass);
                      }
                    }}
                    SliderProps={{ valueLabelDisplay: "auto" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    min: "0",
                  }}
                  fullWidth
                  type={"number"}
                  label={"EV Population"}
                  value={config.evPopulation || 0}
                  onKeyDown={(event) => {
                    if (
                      event.key === "-" ||
                      event.key === "e" ||
                      event.key === "."
                    ) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(event) => {
                    const value = Number(event.target.value);
                    updateGrowthScenarioRelativeFields(value, "evPopulation");
                  }}
                  onBlur={(event) => {
                    const value = Number(event.target.value);
                    const errorMessage = validateEvPopulation(value);
                    setError((prev) => ({
                      ...prev,
                      evPopulation: errorMessage,
                    }));

                    if (!errorMessage) {
                      updateGrowthScenarioRelativeFields(value, "evPopulation");
                    }
                  }}
                  error={error.evPopulation.length > 0}
                  helperText={error.evPopulation}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Box>
                  <HelpTooltip title="The percent of vehicles-in-operation that are EVs.">
                    <Typography>EV Penetration</Typography>
                  </HelpTooltip>
                  <InputSlider
                    min={0}
                    max={100}
                    step={1}
                    value={Number((config.evPenetration * 100).toFixed(2)) || 0}
                    onChange={(newPenetrationRate) => {
                      updateGrowthScenarioRelativeFields(
                        Math.min(Number(newPenetrationRate), 100),
                        "evPenetration"
                      );
                    }}
                    SliderProps={{ valueLabelDisplay: "auto" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>
              <Grid item md={5} xs={12}>
                <Box>
                  <HelpTooltip title="The percent of vehicles replaced each year. This cannot exceed 8%.">
                    <Typography>Base Scrappage Rate</Typography>
                  </HelpTooltip>
                  <InputSlider
                    min={0}
                    max={8}
                    step={1}
                    value={Number((config.scrappageRate * 100).toFixed(2))}
                    onChange={(newScrappageRate) => {
                      if (newScrappageRate <= 8) {
                        const newVehicleClass = structuredClone(vehicleClass);
                        newVehicleClass.config.scrappageRate =
                          Number(newScrappageRate) / 100;
                        props.onVehicleClassChange(newVehicleClass);
                      }
                    }}
                    SliderProps={{ valueLabelDisplay: "auto" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid container columnSpacing={3} marginTop={2}>
            <Grid item md>
              <Typography variant="h3" marginBottom={3} align="center">
                Current Sales
              </Typography>
              <Grid
                container
                direction={"column"}
                columnSpacing={4}
                rowSpacing={3}
              >
                <Grid item>
                  <Box>
                    <HelpTooltip title="The year for which the current achieved market share is reported.">
                      <Typography>Current Year</Typography>
                    </HelpTooltip>
                    <InputSlider
                      min={customSalesCurveYears[0]}
                      max={
                        customSalesCurveYears[customSalesCurveYears.length - 1]
                      }
                      step={1}
                      value={config.currentYear}
                      onChange={(newCurrentYear) => {
                        if (newCurrentYear < config.targetYear) {
                          const newVehicleClass = structuredClone(vehicleClass);
                          newVehicleClass.config.currentYear =
                            Math.round(newCurrentYear);
                          props.onVehicleClassChange(newVehicleClass);
                        }
                      }}
                      SliderProps={{ valueLabelDisplay: "auto" }}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Box>
                    <HelpTooltip title="EV sales as a percent of all vehicle sales for the current year.">
                      <Typography>Current Marketshare</Typography>
                    </HelpTooltip>
                    <InputSlider
                      min={0}
                      max={100}
                      step={1}
                      value={Number(
                        (config.currentMarketshare * 100).toFixed(2)
                      )}
                      onChange={(newCurrentMarketShare) => {
                        if (
                          newCurrentMarketShare <=
                          Math.round(config.targetMarketshare * 100)
                        ) {
                          const newVehicleClass = structuredClone(vehicleClass);
                          newVehicleClass.config.currentMarketshare =
                            Number(newCurrentMarketShare) / 100;
                          props.onVehicleClassChange(newVehicleClass);
                        }
                      }}
                      SliderProps={{ valueLabelDisplay: "auto" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md>
              <Typography variant="h3" marginBottom={3} align="center">
                Interim Sales Targets
              </Typography>
              <Grid
                container
                direction={"column"}
                columnSpacing={4}
                rowSpacing={3}
              >
                <Grid item>
                  <Box>
                    <HelpTooltip title="The year where the EV sales target is set.">
                      <Typography>Target Year</Typography>
                    </HelpTooltip>
                    <InputSlider
                      min={customSalesCurveYears[0]}
                      max={
                        customSalesCurveYears[customSalesCurveYears.length - 1]
                      }
                      step={1}
                      value={config.targetYear}
                      onChange={(newTargetYear) => {
                        if (
                          newTargetYear > config.currentYear &&
                          newTargetYear < config.finalYear
                        ) {
                          const newVehicleClass = structuredClone(vehicleClass);
                          newVehicleClass.config.targetYear =
                            Math.round(newTargetYear);
                          props.onVehicleClassChange(newVehicleClass);
                        }
                      }}
                      SliderProps={{ valueLabelDisplay: "auto" }}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Box>
                    <HelpTooltip title="EV sales as a percent of all vehicle sales for the target year.">
                      <Typography>Target Marketshare</Typography>
                    </HelpTooltip>
                    <InputSlider
                      min={0}
                      max={100}
                      step={1}
                      value={Number(
                        (config.targetMarketshare * 100).toFixed(2)
                      )}
                      onChange={(newTargetMarketshare) => {
                        if (
                          newTargetMarketshare >=
                            Math.round(config.currentMarketshare * 100) &&
                          newTargetMarketshare <=
                            Math.round(config.finalMarketshare * 100)
                        ) {
                          newTargetMarketshare = newTargetMarketshare / 100;
                          const newVehicleClass = structuredClone(vehicleClass);
                          newVehicleClass.config.targetMarketshare =
                            newTargetMarketshare;
                          props.onVehicleClassChange(newVehicleClass);
                        }
                      }}
                      SliderProps={{ valueLabelDisplay: "auto" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md>
              <Typography variant="h3" marginBottom={3} align="center">
                Final Sales Targets
              </Typography>
              <Grid
                container
                direction={"column"}
                columnSpacing={4}
                rowSpacing={3}
              >
                <Grid item>
                  <Box>
                    <HelpTooltip title="The year where EV sales targets will plateau.">
                      <Typography>Final Year</Typography>
                    </HelpTooltip>
                    <InputSlider
                      min={customSalesCurveYears[0]}
                      max={2100}
                      step={1}
                      value={config.finalYear}
                      onChange={(newFinalYear) => {
                        if (newFinalYear > config.targetYear) {
                          const newVehicleClass = structuredClone(vehicleClass);
                          newVehicleClass.config.finalYear =
                            Math.round(newFinalYear);
                          props.onVehicleClassChange(newVehicleClass);
                        }
                      }}
                      SliderProps={{ valueLabelDisplay: "auto" }}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Box>
                    <HelpTooltip title="The final EV sales percent of all vehicles sales.">
                      <Typography>Final Marketshare</Typography>
                    </HelpTooltip>
                    <InputSlider
                      min={0}
                      max={100}
                      step={1}
                      value={Number((config.finalMarketshare * 100).toFixed(2))}
                      onChange={(newFinalMarketshare) => {
                        if (
                          newFinalMarketshare >=
                          Math.round(config.targetMarketshare * 100)
                        ) {
                          newFinalMarketshare = newFinalMarketshare / 100;
                          const newVehicleClass = structuredClone(vehicleClass);
                          newVehicleClass.config.finalMarketshare =
                            newFinalMarketshare;
                          props.onVehicleClassChange(newVehicleClass);
                        }
                      }}
                      SliderProps={{ valueLabelDisplay: "auto" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} marginTop={5}>
          <Grid item md>
            <Accordion
              disableGutters={true}
              sx={{ backgroundColor: "#f4f4f4", marginTop: "1em" }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Additional Controls</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container direction={"column"} rowGap={2}>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={config.scrappageIncentive}
                          value="l1"
                          onChange={(event) => {
                            const newVehicleClass =
                              structuredClone(vehicleClass);
                            newVehicleClass.config.scrappageIncentive =
                              event.target.checked;
                            props.onVehicleClassChange(newVehicleClass);
                          }}
                        />
                      }
                      label="Incentivize EV Scrappage Rate"
                    />
                  </Grid>
                  {config.scrappageIncentive && (
                    <Grid item>
                      <HelpTooltip title="Incentive for car owners to scrap their existing vehicles in exchange for a percentage of the car's MSRP.">
                        <Typography>Scrappage Incentive Size</Typography>
                      </HelpTooltip>
                      <InputSlider
                        min={0}
                        max={100}
                        step={1}
                        value={Number(
                          (config.scrappageIncentiveSize * 100).toFixed(2)
                        )}
                        onChange={(newScrappageIncentiveSize) => {
                          newScrappageIncentiveSize =
                            newScrappageIncentiveSize / 100;
                          const newVehicleClass = structuredClone(vehicleClass);
                          newVehicleClass.config.scrappageIncentiveSize =
                            newScrappageIncentiveSize;
                          props.onVehicleClassChange(newVehicleClass);
                        }}
                        SliderProps={{ valueLabelDisplay: "auto" }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={config.retrofitIncentive}
                          value="l1"
                          onChange={(event) => {
                            const newVehicleClass =
                              structuredClone(vehicleClass);
                            newVehicleClass.config.retrofitIncentive =
                              event.target.checked;
                            props.onVehicleClassChange(newVehicleClass);
                          }}
                        />
                      }
                      label="Incentivize EV Retrofit Rate"
                    />
                  </Grid>
                  {config.retrofitIncentive && (
                    <Grid item>
                      <HelpTooltip title="Incentive for car owners to retrofit their internal combustion engine (ICE) vehicles with electric motors.">
                        <Typography>EV Retrofit Size</Typography>
                      </HelpTooltip>
                      <InputSlider
                        min={0}
                        max={100}
                        step={1}
                        value={Number(
                          (config.retrofitIncentiveSize * 100).toFixed(2)
                        )} // fix floating point inaccuracy
                        onChange={(newRetrofitIncentiveSize) => {
                          newRetrofitIncentiveSize =
                            newRetrofitIncentiveSize / 100;
                          const newVehicleClass = structuredClone(vehicleClass);
                          newVehicleClass.config.retrofitIncentiveSize =
                            newRetrofitIncentiveSize;
                          props.onVehicleClassChange(newVehicleClass);
                        }}
                        SliderProps={{ valueLabelDisplay: "auto" }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Alert severity="info">
              <AlertTitle>Tip</AlertTitle>
              <Typography>
                The sales curve is mathematically fitted to the data. If the
                curve does not look as you desire, you may need to adjust the
                advanced options.
              </Typography>
            </Alert>
          </Grid>
          <Grid item md>
            <Box sx={{ flex: "1" }}>
              <Paper
                elevation={8}
                sx={{
                  width: "100%",
                  height: "100%",
                  padding: "1em",
                  boxSizing: "border-box",
                }}
              >
                <Typography variant="h2">Sales Curve</Typography>
                <Box sx={{ height: "400px" }}>
                  <Chart
                    data={customSalesCurveData}
                    options={customSalesCurveOptions}
                    type={"line"}
                  />
                </Box>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={props.onClose}>
          Cancel
        </Button>
        <div style={{ flex: "1 0 0" }} />
        <Button variant="outlined" onClick={props.onBackButtonClick}>
          Back
        </Button>
        <Button
          variant="contained"
          onClick={props.onNextButtonClick}
          disabled={nextDisabled}
        >
          {buttonText}
        </Button>
      </DialogActions>
    </>
  );
}
