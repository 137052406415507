// Function to format numbers with commas in US format and remove decimals for larger numbers
export function formatNumber(number: number | null): string {
  if (number === undefined || number === null) {
    return ""; // Handle undefined or null values gracefully
  }

  const formattedNumber = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: number >= 1000 ? 0 : 2, // Remove decimals for larger numbers
  }).format(number);

  return formattedNumber;
}

export function formatCurrency(amount: number | null): string {
  if (amount === undefined || amount === null) {
    return ""; // Handle undefined or null values gracefully
  }

  const formattedCurrency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: amount >= 1000 ? 0 : 2, // Remove decimals for larger amounts
  }).format(amount);

  return formattedCurrency;
}
