import { useEffect, useState } from "react";
import { AnnualSeason } from "types/terminal";
import {
  UtilityRateStructure,
  UtilityRateStructureNew,
} from "types/terminal-financial";
import { useAccessToken } from "utils/get-access-token";

export function useUtilityRates(organizationId: number) {
  const [utilityRates, setUtilityRates] = useState<UtilityRateStructure[]>([]);
  const [loadingUtilityRates, setLoadingUtilityRates] = useState(false);
  const [errorLoadingUtilityRates, setErrorLoadingUtilityRates] =
    useState<Error | null>(null);

  const { getToken } = useAccessToken();

  const fetchData = async () => {
    setLoadingUtilityRates(true);
    setErrorLoadingUtilityRates(null);

    try {
      const apiToken = await getToken();

      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/terminals/utility-rates?orgId=${organizationId}`,
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
          },
        }
      );

      if (response.ok) {
        const result: any[] = await response.json();
        setUtilityRates(result);
      } else {
        setUtilityRates([]);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorLoadingUtilityRates(error);
      }
    }

    setLoadingUtilityRates(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const refetch = () => {
    fetchData();
  };

  return {
    utilityRates,
    loadingUtilityRates,
    errorLoadingUtilityRates,
    refetch,
  };
}

type UseSetDefaultUtilityRate = {
  setDefaultUtilityRate: (
    organizationId: number,
    utilityRateId: number
  ) => Promise<void>;
  loadingDefaultUtilityRate: boolean;
  errorUtilityRate: Error | null;
};

export function useSetDefaultUtilityRate(): UseSetDefaultUtilityRate {
  const [loadingDefaultUtilityRate, setLoadingDefaultUtilityRate] =
    useState<boolean>(false);
  const [errorUtilityRate, setErrorUtilityRate] = useState<Error | null>(null);
  const { getToken } = useAccessToken();

  const setDefaultUtilityRate = async (
    organizationId: number,
    utilityRateId: number
  ) => {
    setLoadingDefaultUtilityRate(true);
    setErrorUtilityRate(null);

    try {
      const apiToken = await getToken();
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/terminals/v2/utility-rates/${utilityRateId}?orgId=${organizationId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to set the default utility rate.");
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorUtilityRate(error);
      }
    } finally {
      setLoadingDefaultUtilityRate(false);
    }
  };

  return {
    setDefaultUtilityRate,
    loadingDefaultUtilityRate,
    errorUtilityRate,
  };
}

// New Utility rates API
export function useUtilityRatesForOrganizations(propertyId: number) {
  const [utilityRates, setUtilityRates] = useState<UtilityRateStructureNew[]>(
    []
  );
  const [loadingUtilityRates, setLoadingUtilityRates] = useState(false);
  const [errorLoadingUtilityRates, setErrorLoadingUtilityRates] =
    useState<Error | null>(null);

  const { getToken } = useAccessToken();

  const fetchData = async () => {
    setLoadingUtilityRates(true);
    setErrorLoadingUtilityRates(null);

    try {
      const apiToken = await getToken();

      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/terminals/${propertyId}/v2/utility-rates`,
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
          },
        }
      );

      if (response.ok) {
        const result: any[] = await response.json();
        setUtilityRates(result);
      } else {
        setUtilityRates([]);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorLoadingUtilityRates(error);
      }
    }

    setLoadingUtilityRates(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const refetch = () => {
    fetchData();
  };

  return {
    utilityRates,
    loadingUtilityRates,
    errorLoadingUtilityRates,
    refetch,
  };
}

// New Utility rates API
export function useNewUtilityRateById(utilityRateId: number) {
  const [utilitySeasons, setUtilitySeasons] = useState<AnnualSeason[]>([]);
  const [loadingUtilitySeasons, setLoadingUtilitySeasons] = useState(false);
  const [errorLoadingUtilitySeasons, setErrorLoadingUtilitySeasons] =
    useState<Error | null>(null);

  const { getToken } = useAccessToken();

  const fetchData = async () => {
    setLoadingUtilitySeasons(true);
    setErrorLoadingUtilitySeasons(null);

    try {
      const apiToken = await getToken();

      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/terminals/v2/utility-rates/${utilityRateId}`,
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
          },
        }
      );

      if (response.ok) {
        const result: any[] = await response.json();
        setUtilitySeasons(result);
      } else {
        setUtilitySeasons([]);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorLoadingUtilitySeasons(error);
      }
    }

    setLoadingUtilitySeasons(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const refetch = () => {
    fetchData();
  };

  return {
    utilitySeasons,
    loadingUtilitySeasons,
    errorLoadingUtilitySeasons,
    refetch,
  };
}

export async function loadUtilityDownload(
  utilityRateId: number,
  apiToken: string
) {
  const url = `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/terminals/v2/utility-rates/${utilityRateId}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${apiToken}`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch annualization financial.");
  }
  return response.json();
}
