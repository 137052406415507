import { loadAnnualDownload } from "api/terminal/terminals-annualization";
import * as ExcelJs from "exceljs";
import { saveAs } from "file-saver";
import { Annualization } from "types/terminal";
import { AnnualFinance } from "types/terminal-annualization";
import { MONTHSHORT } from "utils/month";
import { calculateMetaInformation } from "utils/terminal-annual-calculation";

const generate24HourEnergyProfile = (energy: number[]): number[] => {
  return Array.from(
    { length: 24 },
    (_, i) => Number(energy[i * 2]) + Number(energy[i * 2 + 1])
  );
};

const generate24HourPowerProfile = (profile: number[]): number[] => {
  return Array.from({ length: 24 }, (_, i) =>
    Math.max(Number(profile[i * 2]), Number(profile[i * 2 + 1]))
  );
};

const shiftStatuses = ["Idle", "On-shift", "Break"];
const allowedChargingStatuses = ["Not Allowed", "Allowed"];

export const createOuterBorder = (
  worksheet: ExcelJs.Worksheet,
  start = { row: 1, col: 1 },
  end = { row: 1, col: 1 },
  borderWidth: ExcelJs.BorderStyle = "medium"
) => {
  const borderStyle = {
    style: borderWidth,
  };
  for (let i = start.row; i <= end.row; i++) {
    const leftBorderCell = worksheet.getCell(i, start.col);
    const rightBorderCell = worksheet.getCell(i, end.col);
    leftBorderCell.border = {
      ...leftBorderCell.border,
      left: borderStyle,
    };
    rightBorderCell.border = {
      ...rightBorderCell.border,
      right: borderStyle,
    };
  }

  for (let i = start.col; i <= end.col; i++) {
    const topBorderCell = worksheet.getCell(start.row, i);
    const bottomBorderCell = worksheet.getCell(end.row, i);
    topBorderCell.border = {
      ...topBorderCell.border,
      top: borderStyle,
    };
    bottomBorderCell.border = {
      ...bottomBorderCell.border,
      bottom: borderStyle,
    };
  }
};

type AnnualizationData = Annualization & {
  financial: AnnualFinance;
} & {
  metaInfo: {
    highWorkLoadDays: number;
    mediumWorkLoadDays: number;
    lowWorkLoadDays: number;
    peakMonthWorkload: string;
    peakMonthPowerDraw: string;
    peakMonthEnergyConsumption: string;
  };
};

export const annualizationDownload = async (
  selectedAnnualIds: number[],
  apiToken: string
) => {
  let downloadSuccess = true;
  let annualizationData: AnnualizationData[] = [];
  const annualUsage: Record<
    string,
    { energyUsage: number[]; demandUsage: number[] }
  > = {};

  MONTHSHORT.forEach((_, index) => {
    annualUsage[index + 1] = {
      energyUsage: Array.from({ length: 24 }, (_) => 0),
      demandUsage: Array.from({ length: 24 }, (_) => 0),
    };
  });

  const responses = await Promise.all(
    selectedAnnualIds.map(async (annualId) => {
      const response: AnnualizationData = await loadAnnualDownload(
        annualId,
        apiToken
      );
      if (response.configs?.length) {
        response.configs.forEach((config) => {
          const daysApplied = config.daysApplied;
          config.seasons.forEach((season) => {
            const energy = generate24HourEnergyProfile(
              season.energyUsageKwhDatapoints
            );
            const demand = generate24HourPowerProfile(
              season.demandUsageKwDatapoints
            );
            season.months.forEach((month) => {
              // Aggregate hourly data
              for (let hour = 0; hour < 24; hour++) {
                annualUsage[month].energyUsage[hour] +=
                  daysApplied[month - 1] * energy[hour] || 0;
                annualUsage[month].demandUsage[hour] = Math.max(
                  annualUsage[month].demandUsage[hour],
                  demand[hour] || 0
                );
              }
            });
          });
        });
      }

      const metaInfo = calculateMetaInformation(response.financial);
      return { ...response, metaInfo };
    })
  );
  annualizationData.push(...responses);

  // Need to use this loop to download all the selected annualization data.
  // responses.forEach((annualizaion, index) => {
  //   console.log(annualizaion, "each annualization data", index);
  // });
  try {
    annualizationData.forEach(async (downloadData) => {
      const wb = new ExcelJs.Workbook();
      const sheet1 = wb.addWorksheet("Instructions");

      sheet1.addRow(["Terminal Annualization Overview"]);
      sheet1.getCell("A1").font = { bold: true, underline: true };
      sheet1.addRow(["Tab 1 - Annualization Summary "]);
      sheet1.addRow(["1.1", "Annualization Scenario Inputs"]);
      sheet1.getCell("A3").alignment = { horizontal: "right" };
      sheet1.addRow(["1.2", "Financial Projection Outputs"]);
      sheet1.getCell("A4").alignment = { horizontal: "right" };
      sheet1.addRow(["1.3", "Electric Vehicle Recommendations"]);
      sheet1.getCell("A5").alignment = { horizontal: "right" };
      sheet1.addRow(["1.4", "Charger Recommendations"]);
      sheet1.getCell("A6").alignment = { horizontal: "right" };

      sheet1.addRow(["Tab 2 - Annualization Details"]);
      sheet1.addRow(["2.1", "Utility Cost Outputs"]);
      sheet1.getCell("A8").alignment = { horizontal: "right" };
      sheet1.addRow(["2.2", "EV Energy Use Outputs"]);
      sheet1.getCell("A9").alignment = { horizontal: "right" };
      sheet1.addRow(["2.3", "Charging Power Demand Outputs"]);
      sheet1.getCell("A10").alignment = { horizontal: "right" };
      sheet1.addRow(["2.4", "Utility Rate Usage Charge"]);
      sheet1.getCell("A11").alignment = { horizontal: "right" };
      sheet1.addRow(["2.5", "Utility Demand Charge"]);
      sheet1.getCell("A12").alignment = { horizontal: "right" };

      sheet1.addRow(["Tab 3 - Configuration Details"]);
      sheet1.addRow(["3.1", "Configuration Details"]);
      sheet1.getCell("A14").alignment = { horizontal: "right" };
      sheet1.addRow(["3.1.1", "Shift Schedule"]);
      sheet1.getCell("A15").alignment = { horizontal: "right" };
      sheet1.addRow(["3.1.2", "Allowed Charging hours"]);
      sheet1.getCell("A16").alignment = { horizontal: "right" };
      sheet1.addRow(["3.1.3", "Days Applied"]);
      sheet1.getCell("A17").alignment = { horizontal: "right" };
      sheet1.addRow(["3.1.4", "Daily Energy Usage (kWh)"]);
      sheet1.getCell("A18").alignment = { horizontal: "right" };
      sheet1.addRow(["3.1.5", "Daily Power Demand (kW)"]);
      sheet1.getCell("A19").alignment = { horizontal: "right" };
      sheet1.getRow(20).addPageBreak();
      sheet1.addRow(["Key Definitions"]);
      sheet1.addRow([
        "Utility Cost ($/month)",
        "Total monthly electricity expenses, including the cost of energy usage, the cost of power demand, and any other monthly charges.",
      ]);
      sheet1.addRow([
        "Energy Usage Charge ($/kWh)",
        "The cost of per kilowatt-hour of electricity consumed",
      ]);
      sheet1.addRow([
        "Demand Charge ($/kW)",
        "The cost for maintaining electrical system capacity to meet the customer's highest demand during the period",
      ]);
      sheet1.addRow([
        "Energy Usage (kWh)",
        "The total amount of electricity consumed in kilowatt-hours during the specified period",
      ]);
      sheet1.addRow([
        "Power Demand (kW)",
        "The maximum amount of power consumed in kilowatts during the specified period",
      ]);

      // -------------------------------------------------------------------------------------------------------
      // SHEET 2
      const sheet2 = wb.addWorksheet("Annualization Summary");

      sheet2.addRow(["Annualization Summary"]);
      sheet2.getCell("A1").font = { bold: true, underline: true };

      let row = sheet2.getRow(2);
      row.addPageBreak();

      sheet2.addRow(["Scenario Name", downloadData?.name]);
      sheet2.getCell("A3").font = { bold: true };

      row = sheet2.getRow(4);
      row.addPageBreak();

      sheet2.addRow(["Annualization Scenario Inputs"]);
      sheet2.getCell("A5").font = { bold: true };
      sheet2.addRow([
        "",
        "Number of High Workload Days",
        downloadData.metaInfo.highWorkLoadDays,
      ]);
      sheet2.addRow([
        "",
        "Number of Low Workload Days",
        downloadData.metaInfo.lowWorkLoadDays,
      ]);
      sheet2.addRow([
        "",
        "Number of Other Workload Days",
        downloadData.metaInfo.mediumWorkLoadDays,
      ]);
      sheet2.addRow([
        "",
        "Peak Month(s) by Workload",
        downloadData.metaInfo.peakMonthWorkload,
      ]);
      sheet2.addRow([
        "",
        "Peak Month(s) by Power Draw",
        downloadData.metaInfo.peakMonthPowerDraw,
      ]);
      sheet2.addRow([
        "",
        "Peak Month(s) by Energy Consumption",
        downloadData.metaInfo.peakMonthEnergyConsumption,
      ]);

      row = sheet2.getRow(12);
      row.addPageBreak();

      sheet2.addRow(["Financial Projection Outputs"]);
      sheet2.getCell("A13").font = { bold: true };
      sheet2.addRow([
        "",
        "Optimal EV Count (#)",
        downloadData.fleetSize - downloadData.evReserve,
      ]);
      sheet2.addRow([
        "",
        "Number of EVs in Reserve (#)",
        downloadData.evReserve,
      ]);
      sheet2.addRow(["", "Total Vehicle Count (#)", downloadData.fleetSize]);
      sheet2.addRow([
        "",
        "Optimal Charger Count (#)",
        downloadData.optNumChargers,
      ]);
      sheet2.addRow([
        "",
        "Vehicle Price (USD)",
        downloadData.financial.workLoads.configs[0].vehicleCost ||
          downloadData.evVehicle.priceUsd,
      ]);
      sheet2.addRow([
        "",
        "Charger Price (USD)",
        downloadData.financial.workLoads.configs[0].chargerCost ||
          downloadData.charger.priceUsd,
      ]);
      sheet2.addRow([
        "",
        "Charger Installation Cost (USD)",
        downloadData.financial.financialInformation.totalCapitalExpenses
          .installationExpenses,
      ]);
      sheet2.addRow([
        "",
        "Vehicle CapEx (USD)",
        downloadData.financial.financialInformation.totalCapitalExpenses
          .vehicleExpenses,
      ]);
      sheet2.addRow([
        "",
        "Charger CapEx (USD)",
        downloadData.financial.financialInformation.totalCapitalExpenses
          .chargerExpenses,
      ]);
      sheet2.addRow([
        "",
        "Installation CapEx (USD)",
        downloadData.financial.financialInformation.totalCapitalExpenses
          .installationExpenses,
      ]);
      sheet2.addRow([
        "",
        "Total CapEx (USD)",
        downloadData.financial.financialInformation.totalCapitalExpenses
          .totalCapitalExpenses,
      ]);
      sheet2.addRow([
        "",
        "Utility Cost (USD)",
        downloadData.financial.financialInformation.totalOperationalExpenses
          .fuelCosts,
      ]);
      sheet2.addRow([
        "",
        "Maintenance Cost (USD)",
        downloadData.financial.financialInformation.totalOperationalExpenses
          .maintenanceCosts,
      ]);
      sheet2.addRow([
        "",
        "Total Annual OpEx (USD)",
        downloadData.financial.financialInformation.totalOperationalExpenses
          .totalOperationalExpenses,
      ]);
      sheet2.addRow([
        "",
        "Net Present Value (USD)",
        downloadData.financial.totalCost.totalExpensesNPV,
      ]);

      row = sheet2.getRow(29);
      row.addPageBreak();

      sheet2.addRow(["Electric Vehicle Recommendations"]);
      sheet2.getCell("A30").font = { bold: true };
      sheet2.addRow([
        "",
        "Selected Vehicle Type",
        downloadData.vehicleType.name,
      ]);
      sheet2.addRow([
        "",
        "Optimal Active EVs",
        downloadData.fleetSize - downloadData.evReserve,
      ]);
      sheet2.addRow(["", "Number of EVs in Reserve", downloadData.evReserve]);
      sheet2.addRow(["", "Make", downloadData.evVehicle.make]);
      sheet2.addRow(["", "Model", downloadData.evVehicle.model]);
      sheet2.addRow([
        "",
        "Battery Capacity (kWh)",
        downloadData.evVehicle.batteryCapacityKwh,
      ]);
      sheet2.addRow([
        "",
        "Battery Max Charge Rate (kW)",
        downloadData.evVehicle.batteryMaxChargeRateKw,
      ]);
      sheet2.addRow([
        "",
        "Buy America Compliant",
        downloadData.evVehicle.buyAmericaCompliance,
      ]);
      sheet2.addRow([
        "",
        "Price (USD)",
        downloadData.financial.workLoads.configs[0].vehicleCost,
      ]);

      row = sheet2.getRow(40);
      row.addPageBreak();

      sheet2.addRow(["Charger Recommendations"]);
      sheet2.getCell("A41").font = { bold: true };
      sheet2.addRow(["", "Model", downloadData.charger.model]);
      sheet2.addRow(["", "Optimal Count", downloadData.optNumChargers]);
      sheet2.addRow([
        "",
        "Charge Rate (kW)",
        downloadData.charger.chargeRateKw,
      ]);
      sheet2.addRow(["", "Voltage", downloadData.charger.voltage]);
      sheet2.addRow(["", "Current", downloadData.charger.amperage]); //doubt
      sheet2.addRow([
        "",
        "Price (USD)",
        downloadData.financial.workLoads.configs[0].chargerCost,
      ]);

      // ------------------------------------------------------------------------------------------------
      // SHEET 3
      const sheet3 = wb.addWorksheet("Annualization Details");

      sheet3.addRow(["Annualization Details"]);
      sheet3.getCell("A1").font = { bold: true };
      sheet3.insertRow(2, ["Utility Cost Estimation"]);
      sheet3.getCell("A2").font = { bold: true };

      Array.from({ length: 12 }, (_, index) => {
        sheet3.addRow([
          "",
          MONTHSHORT[index],
          downloadData.financial.utilityCost[index].monthlyCost,
        ]);
      });
      //createOuterBorder(sheet3, { row: 19, col: 2 }, { row: 30, col: 3 });

      sheet3.insertRow(16, ["EV Energy Use Outputs"]);
      sheet3.getCell("A16").font = { bold: true };
      sheet3.addRow([
        "",
        "",
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
      ]);
      MONTHSHORT.forEach((val, index) => {
        const monthIndex = index + 1;
        const season = annualUsage[monthIndex];
        sheet3.addRow(["", val, ...(season.energyUsage ?? [])]);
      });
      createOuterBorder(sheet3, { row: 17, col: 2 }, { row: 29, col: 26 });

      sheet3.insertRow(31, ["Charging Power Demand Outputs"]);
      sheet3.getCell("A31").font = { bold: true };
      sheet3.addRow([
        "",
        "",
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
      ]);
      MONTHSHORT.forEach((val, index) => {
        const monthIndex = index + 1;
        const season = annualUsage[monthIndex];
        sheet3.addRow(["", val, ...(season.demandUsage ?? [])]);
      });
      createOuterBorder(sheet3, { row: 32, col: 2 }, { row: 44, col: 26 });

      sheet3.insertRow(46, ["Utility Rate Usage Charge"]);
      sheet3.getCell("A46").font = { bold: true };
      sheet3.addRow([
        "Fixed Monthly Charge (USD)",
        downloadData.utilityRates.fixedMonthlyFees,
      ]);
      sheet3.addRow(["Energy Charges ($/kWh)"]);
      sheet3.getCell("A48").font = { bold: true };
      sheet3.addRow([
        "",
        "",
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
      ]);
      MONTHSHORT.forEach((val, index) => {
        const monthIndex = index + 1;
        const season = downloadData.utilityRates.seasons.find((s) =>
          s.months.includes(monthIndex)
        );
        sheet3.addRow(["", val, ...(season?.energyUsageCharge ?? [])]);
      });

      createOuterBorder(sheet3, { row: 49, col: 2 }, { row: 61, col: 26 });

      sheet3.insertRow(63, ["Demand Charges ($/kW)"]);
      sheet3.getCell("A63").font = { bold: true };
      sheet3.addRow([
        "",
        "",
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
      ]);
      MONTHSHORT.forEach((val, index) => {
        const monthIndex = index + 1;
        const season = downloadData.utilityRates.seasons.find((s) =>
          s.months.includes(monthIndex)
        );
        sheet3.addRow(["", val, ...(season?.demandUsageCharge ?? [])]);
      });
      createOuterBorder(sheet3, { row: 64, col: 2 }, { row: 76, col: 26 });

      // ------------------------------------------------------------------------------------------------
      // SHEET 4
      const sheet4 = wb.addWorksheet("Configuration Details");

      sheet4.addRow(["Configuration Details"]);
      sheet4.getCell("A1").font = { bold: true, underline: true };

      let rowNumber = 3;
      if (downloadData.configs?.length) {
        downloadData.configs.forEach((config, index) => {
          rowNumber = 3 + index * 70;
          sheet4.insertRow(rowNumber, [
            "Configuration Name",
            config.scenarioName,
          ]);
          sheet4.getCell("A" + rowNumber).font = { bold: true };

          sheet4.insertRow(rowNumber + 2, ["Configuration Details"]);
          sheet4.getCell("A" + (rowNumber + 2)).font = { bold: true };
          sheet4.insertRow(rowNumber + 3, [
            "",
            "Terminal",
            downloadData.property.name,
          ]);
          sheet4.insertRow(rowNumber + 4, [
            "",
            "Cost Center",
            downloadData.terminalFacility.name,
          ]);
          sheet4.insertRow(rowNumber + 5, [
            "",
            "Vehicle Type",
            downloadData.vehicleType.name,
          ]);
          sheet4.insertRow(rowNumber + 6, [
            "",
            "Current Fleet Size",
            downloadData.fleetSize,
          ]);
          sheet4.insertRow(rowNumber + 7, [
            "",
            "ICE Fuel Consumption (gph)",
            config.iceFuelConsumption,
          ]);
          sheet4.insertRow(rowNumber + 8, [
            "",
            "EV Downtime Percentage",
            config.evDowntimePct * 100,
          ]);
          sheet4.insertRow(rowNumber + 9, [
            "",
            "ICE Downtime Percentage",
            config.iceDowntimePct * 100,
          ]);
          sheet4.insertRow(rowNumber + 10, [
            "",
            "EV Maintenance Cost Percentage",
            config.vehicleMaintenanceCostPct * 100,
          ]);
          sheet4.insertRow(rowNumber + 11, [
            "",
            "ICE Maintenance Cost Percentage",
            config.iceVehicleMaintenanceCostPct * 100,
          ]);
          sheet4.insertRow(rowNumber + 12, [
            "",
            "EV Battery Buffer Percentage",
            config.evBatteryBuffer,
          ]);
          sheet4.insertRow(rowNumber + 13, [
            "",
            "Vehicles Per Shift",
            config.vehiclesPerShift,
          ]);

          sheet4.getRow(rowNumber + 14).addPageBreak();

          sheet4.insertRow(rowNumber + 15, ["Shift Schedule"]);
          sheet4.getCell("A" + (rowNumber + 15)).font = { bold: true };
          sheet4.addRow([
            "",
            "Midnight",
            "12:30 AM",
            "1:00 AM",
            "1:30 AM",
            "2:00 AM",
            "2:30 AM",
            "3:00 AM",
            "3:30 AM",
            "4:00 AM",
            "4:30 AM",
            "5:00 AM",
            "5:30 AM",
            "6:00 AM",
            "6:30 AM",
            "7:00 AM",
            "7:30 AM",
            "8:00 AM",
            "8:30 AM",
            "9:00 AM",
            "9:30 AM",
            "10:00 AM",
            "10:30 AM",
            "11:00 AM",
            "11:30 AM",
            "Noon",
            "12:30 PM",
            "1:00 PM",
            "1:30 PM",
            "2:00 PM",
            "2:30 PM",
            "3:00 PM",
            "3:30 PM",
            "4:00 PM",
            "4:30 PM",
            "5:00 PM",
            "5:30 PM",
            "6:00 PM",
            "6:30 PM",
            "7:00 PM",
            "7:30 PM",
            "8:00 PM",
            "8:30 PM",
            "9:00 PM",
            "9:30 PM",
            "10:00 PM",
            "10:30 PM",
            "11:00 PM",
            "11:30 PM",
          ]);
          sheet4.addRow([
            "",
            ...config.shiftSchedule.map((shift) => shiftStatuses[shift]),
          ]);

          sheet4.getRow(rowNumber + 18).addPageBreak();
          sheet4.addRow(["Allowed Charging Hours"]);
          sheet4.getCell("A" + (rowNumber + 19)).font = { bold: true };
          sheet4.addRow([
            "",
            "Midnight",
            "12:30 AM",
            "1:00 AM",
            "1:30 AM",
            "2:00 AM",
            "2:30 AM",
            "3:00 AM",
            "3:30 AM",
            "4:00 AM",
            "4:30 AM",
            "5:00 AM",
            "5:30 AM",
            "6:00 AM",
            "6:30 AM",
            "7:00 AM",
            "7:30 AM",
            "8:00 AM",
            "8:30 AM",
            "9:00 AM",
            "9:30 AM",
            "10:00 AM",
            "10:30 AM",
            "11:00 AM",
            "11:30 AM",
            "Noon",
            "12:30 PM",
            "1:00 PM",
            "1:30 PM",
            "2:00 PM",
            "2:30 PM",
            "3:00 PM",
            "3:30 PM",
            "4:00 PM",
            "4:30 PM",
            "5:00 PM",
            "5:30 PM",
            "6:00 PM",
            "6:30 PM",
            "7:00 PM",
            "7:30 PM",
            "8:00 PM",
            "8:30 PM",
            "9:00 PM",
            "9:30 PM",
            "10:00 PM",
            "10:30 PM",
            "11:00 PM",
            "11:30 PM",
          ]);
          sheet4.addRow([
            "",
            ...config.allowedChargingHours.map(
              (allowed) => allowedChargingStatuses[allowed]
            ),
          ]);

          sheet4.getRow(rowNumber + 22).addPageBreak();
          sheet4.insertRow(rowNumber + 23, ["Days Applied"]);
          sheet4.getCell("A" + (rowNumber + 23)).font = { bold: true };

          MONTHSHORT.forEach((val, index) => {
            sheet4.addRow(["", val, config.daysApplied[index]]);
          });

          sheet4.insertRow(rowNumber + 37, ["Daily Energy Usage (kWh)"]);
          sheet4.getCell("A" + (rowNumber + 37)).font = { bold: true };
          sheet4.addRow([
            "",
            "",
            0,
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
          ]);
          MONTHSHORT.forEach((val, index) => {
            const monthIndex = index + 1;
            const season = config.seasons.find((s) =>
              s.months.includes(monthIndex)
            );
            sheet4.addRow([
              "",
              val,
              ...generate24HourEnergyProfile(
                season?.energyUsageKwhDatapoints ?? []
              ),
            ]);
          });
          createOuterBorder(
            sheet4,
            { row: rowNumber + 38, col: 2 },
            { row: rowNumber + 50, col: 26 }
          );

          sheet4.insertRow(rowNumber + 52, ["Daily Power Demand (kW)"]);
          sheet4.getCell("A" + (rowNumber + 52)).font = { bold: true };
          sheet4.addRow([
            "",
            "",
            0,
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
          ]);
          MONTHSHORT.forEach((val, index) => {
            const monthIndex = index + 1;
            const season = config.seasons.find((s) =>
              s.months.includes(monthIndex)
            );
            sheet4.addRow([
              "",
              val,
              ...generate24HourPowerProfile(
                season?.demandUsageKwDatapoints ?? []
              ),
            ]);
          });
          createOuterBorder(
            sheet4,
            { row: rowNumber + 53, col: 2 },
            { row: rowNumber + 65, col: 26 }
          );
        });
      }

      const filename = `annualization_download-${downloadData?.name}.xlsx`;
      await wb.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, filename);
      });
    });
  } catch (error) {
    console.log(error);
    downloadSuccess = false;
  }
  return downloadSuccess;
};
